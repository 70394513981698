.slide-in-up {
    animation: slideInUp 0.5s ease-out forwards;
  }

  .slide-down {
    animation: slideDown 0.5s ease-out forwards;
  }

  @keyframes slideInUp {
    from { transform: translateY(100%); }
    to { transform: translateY(0); }
  }

  @keyframes slideDown {
    from { transform: translateY(0); }
    to { transform: translateY(100%); }
  }