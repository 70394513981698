.App {
  text-align: center;
}
body {
  background-color: #252323;
  overflow-x: hidden;
}
@font-face {
  font-family: "goodTime";
  src: url("../src/assets/Fonts/goodtimesrg.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-background {
  background-color: #121212;
}

.App-header {
  background-color: #121212;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* Custom styles for toast notifications */

/* Toast container */
.react-hot-toast-container {
  /* Your container styles here */
  background-color: #333;
  color: white;
}

/* Toast item */
.react-hot-toast-toast {
  /* Your toast item styles here */
  background-color: #555;
  color: #fff;
  border: 2px solid #eee;
}

/* Toast icon */
.react-hot-toast-icon {
  /* Your icon styles here */
  fill: yellow;
}
.MuiFormHelperText-root.Mui-error.MuiFormHelperText-sizeSmall.MuiFormHelperText-contained.css-he4hkp-MuiFormHelperText-root {
  margin-left: 0px !important;
}
