/* .gradient-background {
    background-image: none;
                  
    background-size: 100% 100%, 100% 100%;
    background-position: top right, center;
  } */
  
  .slick-next{
    right: 0px !important;
  }
  .slick-prev{
    left: 0px !important;
    z-index: 99;
  }