.button {
  background-color: #ffffff !important; /* Initial background color */
  color:#af22c8  !important;
  transition: background-color 0.3s ease  !important;
  border:solid 1px #af22c8   !important;
  box-shadow: none !important;
}

.button:hover {
  background:linear-gradient(to right, #7D3CE0, #af22c8)!important; /* Initial background color */
  color:#fff  !important;
  border-radius: 2;
}

.file-input-container {
  position: relative;
  display: inline-block;
}

.choose-file-button {
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
}

.hidden-file-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.customFileInput {
  background-color: white; /* Set the white background */
  border: 1px solid white; /* Set a white border (optional) */
  color: black; /* Set the text color (optional) */
  padding: 5px 10px; /* Add padding for better styling */
  transition: background-color 0.3s; /* Smooth transition effect for hover */
  cursor: pointer; /* Change the cursor to a pointer */
  
}

.customFileInput:hover {
  background-color: purple; /* Change the background color to purple on hover */
  color: white;
}