/* Pagination container */
.pagination {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
  }
  
  /* Individual page item */
  .pagination li {
    display: inline-block;
    margin-right: 5px;
    background-color: white; /* Background color for page numbers */
    color: #af22c8; /* Text color for page numbers */
    padding: 5px 10px;
    border: 1px solid #af22c8; /* Border color for page numbers */
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s; /* Transition effect for background and text color */
  }
  
  /* Active page item */
  .pagination .active {
    background-color: #af22c8; /* Background color for the active page */
    color: white; /* Text color for the active page */
    padding: 5px 10px;
    border-radius: 5px;
  }
  
  /* Previous and Next buttons */
  .pagination .previous,
  .pagination .next {
    display: inline-block;
    background-color: white; /* Background color for Previous and Next buttons */
    color: #af22c8; /* Text color for Previous and Next buttons */
    padding: 5px 10px;
    border: 1px solid #af22c8; /* Border color for Previous and Next buttons */
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s; /* Transition effect for background and text color */
  }
  
  /* Disable Previous and Next buttons when not clickable */
  .pagination .disabled {
    pointer-events: none;
    opacity: 0.5;
  }
  
  /* Change cursor to pointer on hover */
  .pagination li:hover,
.pagination .previous:hover,
.pagination .next:hover {
  cursor: pointer;
  background-color: #e0abe6; /* Slightly darker background color on hover */
}
  /* Click effect for Previous and Next buttons */
  .pagination .previous:active,
  .pagination .next:active {
    background-color: #af22c8;
    color: white;
  }
  