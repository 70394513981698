

.button {
  background:linear-gradient(to right, #7D3CE0, #af22c8)!important; /* Initial background color */
  color:#fff  !important;
  transition: background-color 0.5s ease  !important;
  /* border:solid 1px #af22c8   !important; */
  border-radius: 2;
}
.button:hover {
  background: #ffffff !important; /* Initial background color */
    color:#af22c8  !important;
    transition: background-color 0.3s ease  !important;
    box-shadow: none !important;
  }
  
 
  .button2 {
    background-color: #ffffff !important; /* Initial background color */
    color:#af22c8  !important;
    transition: background-color 0.3s ease  !important;
    border:solid 1px #af22c8   !important;
    box-shadow: none !important;
  }
  
  .button2:hover {
    background:linear-gradient(to right, #7D3CE0, #af22c8)!important; /* Initial background color */
    color:#fff  !important;
    border-radius: 2;
  }