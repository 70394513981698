.home {
  background: linear-gradient(
    225deg,
    #731686 1%,      /* Start with your primary color */
    #731686 1%,     /* Make the initial transition */
    transparent 10%, /* Add a transparent section */
  transparent 80%, /* Add another transparent section */
  #af22c8 100%,     /* Continue with your secondary color */
  #af22c8 100% 
  );
  padding-top: 5px;
  }
 