.header {
  height: 80px;
  background-color: rgb(0, 0, 0); /* Customize the background color */
  /* Add any other styles you need for your header here */
  /* padding-top: 10px; */
  font-family: poppins, sans-serif;
}

.pointer-on-hover {
  cursor: pointer;
  transition: all 0.3s ease; /* Add a transition for a smooth effect */
}
.pointer-on-hover:hover {
  transform: scale(1.1); /* Scale the image to 110% on hover */
}
.custom-button {
  background-color: transparent;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
}

/* Change text color to purple on hover */
.custom-button:hover {
  color: #bf40bf;
  border-color: #bf40bf; /* Optionally, you can change the border color as well */
  font-size: 16px;
  font-weight: bold !important;
}

.MuiMenu-list[role="menu"] {
  background-color: #000000;

  color: #fff; /* Use shorthand notation for color */
  font-weight: 300; /* 'light' is not a valid value for font-weight */
  font-family: "Poppins", sans-serif; /* Use single quotes for font names with spaces */
}

.MuiMenu-list[role="menu"] .MuiMenuItem-root {
  font-size: 13px;
  color: #ffffff;

  font-family: "Poppins", sans-serif;
}

.MuiMenu-list[role="menu"] .MuiMenuItem-root:hover {
  background-color: #7a7a7a;
  color: #ffffff;
  font-size: 13px;
  /* 'light' is not a valid value for font-weight */
  font-family: "Poppins", sans-serif;
}

.MuiMenu-list[role="icon"] .MuiMenuItem-root:hover {
  background-color: #7a7a7a;
  color: #bf40bf;
  font-size: 13px;

  font-family: "Poppins", sans-serif;
}
.icon {
  color: rgb(255, 255, 255); /* Set the initial color to red */
}

.custom-button:hover .icon {
  color: #bf40bf; /* Change the color to blue when the button is hovered */
}

/* CSS equivalent of the provided sx styles */
.custom-button-connect {
  background: white;
  color: black;
  width: 100%;
}

.custom-button-connect:hover {
  background-color: #bf40bf; /* Change the background color on hover to purple */
  color: white;
}

.custom-button-connect:active {
  background-color: #bf40bf;
  color: white; /* Change the background color on click to purple */
}

.connect-container button.tw-connect-wallet {
  height: 36px !important;
  width: 150px !important;
  min-width: 150px !important;
  text-transform: uppercase;
}
.connect-container button.tw-connected-wallet {
  width: 150px !important;
  min-width: 150px !important;
}
