body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont,  'Good Times', 
    sans-serif; */
 
}
@font-face {
  font-family: 'Good Times';
  src: local('Good Times'), url("./assets/Fonts/goodtimesrg.otf") format('otf');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* scrollStyles.css */
/* Customize the scrollbar's width, color, and other properties as needed */

/* Webkit (Chrome, Safari, Edge) */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 6px;
}

/* Media query for small screens (adjust the max-width as needed) */
@media screen and (max-width: 768px) {
  ::-webkit-scrollbar {
    width: 4px;
    border-radius: 2px;
  }
}
::-webkit-scrollbar-track {
  background: transparent;
}

/* Firefox */
/* Customize the scrollbar's width, color, and other properties as needed */

/* Customize the scrollbar's width, color, and other properties as needed */
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: af22c8 transparent;
}
