.custom-scroll::-webkit-scrollbar {
    width: 10px !important;
    height: 5px !important;
}
.custom-scroll::-webkit-scrollbar-track {
    background:#00304773;
}
.custom-scroll::-webkit-scrollbar-thumb {
    background: #FFFFFF;
    border-radius: 10px;
}
.custom-scroll::-webkit-scrollbar-thumb:hover {
    background: #FFFFFF;
}