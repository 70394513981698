.button {
    background-color: #4F0FB1 !important; /* Initial background color */
    color:#ffff  !important;
    transition: background-color 0.3s ease  !important;
     border: 0;
    box-shadow: none !important;
 
  }
  
  .button:hover {
    background:linear-gradient(to right, #4F0FB1, rgb(113, 31, 127))!important; /* Initial background color */
    color:#fff  !important;
    /* border-radius: 2; */
    transition: background-color 0.3s ease  !important;
  }